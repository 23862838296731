const competitions = {
  
  IslamicCompetitions: [
    {
      "title": "Islamic Competitions",
      "Events": ["Naat (Boys & Girls)", "Qirat (Boys & Girls)", "Islamic Calligraphy"],
      "EventDetail": [
        {
          "title": "Naat (Boys & Girls)",
          "winner": "7000/-",
          "firstRunner": "3500/-",
          "total": "10500/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-",
          "imgSrc": "https://your-image-url.com/naat.jpg",
          "rules": [
            "Time limit for Naat will be 5-7 minutes.",
            "Marks will be deducted if time exceeds the specified limit.",
            "Naat must be prepared in Urdu language.",
            "Only Naats from authentic/well-reputed poets will be considered.",
            "Song-oriented style will not be allowed.",
            "Only Sana-e-Rasool (SAW) is allowed. Marsia, Hamd, and Munqabat are not allowed in the Na'at competition.",
            "Decision of judges will be final."
          ]
        },
        {
          "title": "Qirat (Boys & Girls)",
          "winner": "5000/-",
          "firstRunner": "3000/-",
          "total": "8000/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-",
          "imgSrc": "https://your-image-url.com/qirat.jpg",
          "rules": [
            "Time limit for Qirat will be 5-7 minutes.",
            "Marks will be deducted if time exceeds the specified limit.",
            "Qirat can be in any style (MISRI or HADAR).",
            "Decision of judges will be final.",
            "Participants of Qiraat competition will be judged on the following criteria:",
            "  - Accuracy of Pronunciation: 60 Marks",
            "  - Melodiousness: 20 Marks",
            "  - Ease and Expertise in Delivery: 20 Marks"
          ]
        },
        {
          "title": "Islamic Calligraphy",
          "winner": "7000/-",
          "firstRunner": "3500/-",
          "total": "10500/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-",
          "imgSrc": "https://your-image-url.com/calligraphy.jpg",
          "rules": [
            "Theme (Arabic script) will be announced at the start of the event.",
            "Participants should prepare the sheet before the start of the competition; no extra time will be given.",
            "Participants will be given 1 hour for the competition.",
            "All modern calligraphy styles are accepted.",
            "Paper size: A3 size.",
            "Script: The applicant may use traditional scripts with original, innovative interpretation.",
            "Material: The applicant is free to choose the paper material (Canvas Fabric if available).",
            "Decision of the judge will be final."
          ]
        }
      ]
    }
  ],
  EntrepreneurialActivities: [
    {
      "title": "Entrepreneurial Activities",
      "Events": [
        "Start-Up Championship",
        "Battle Of Brands",
        "Applied Sciences Project Presentation",
        "Computing Project Presentation",
        "Engineering Project Presentation"
      ],
      "EventDetail": [
        {
          "title": "Start-Up Championship",
          "winner": "15000/-",
          "firstRunner": "10000/-",
          "total": "25000/-",
          "registrationReg": "2500/-",
          "earlyBird": "2200/-"
        },
        {
          "title": "Battle Of Brands",
          "winner": "20000/-",
          "firstRunner": "15000/-",
          "total": "35000/-",
          "registrationReg": "4000/-",
          "earlyBird": "3500/-"
        },
        {
          "title": "Applied Sciences Project Presentation",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "1500/-",
          "earlyBird": "1200/-"
        },
        {
          "title": "Computing Project Presentation",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "1500/-",
          "earlyBird": "1200/-"
        },
        {
          "title": "Engineering Project Presentation",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "1500/-",
          "earlyBird": "1200/-"
        }
      ]
    }
  ],
  EGamesCompetitions: [
    {
      "title": "E-Games Competitions",
      "Events": [
        "VALORANT",
        "TEKKEN",
        "COD 4",
        "DOTA",
        "FIFA",
        "CS GO",
        "Street Fighter"
      ],
      "EventDetail": [
        {
          "title": "VALORANT",
          "winner": "25000/-",
          "firstRunner": "15000/-",
          "total": "40000/-",
          "registrationReg": "3000/-",
          "earlyBird": "2500/-"
        },
        {
          "title": "TEKKEN",
          "winner": "20000/-",
          "firstRunner": "10000/-",
          "total": "30000/-",
          "registrationReg": "1500/-",
          "earlyBird": "1200/-"
        },
        {
          "title": "COD 4",
          "winner": "25000/-",
          "firstRunner": "15000/-",
          "total": "40000/-",
          "registrationReg": "3000/-",
          "earlyBird": "2500/-"
        },
        {
          "title": "DOTA",
          "winner": "20000/-",
          "firstRunner": "10000/-",
          "total": "30000/-",
          "registrationReg": "2500/-",
          "earlyBird": "2200/-"
        },
        {
          "title": "FIFA",
          "winner": "15000/-",
          "firstRunner": "10000/-",
          "total": "25000/-",
          "registrationReg": "2000/-",
          "earlyBird": "1800/-"
        },
        {
          "title": "CS GO",
          "winner": "25000/-",
          "firstRunner": "15000/-",
          "total": "40000/-",
          "registrationReg": "2000/-",
          "earlyBird": "1800/-"
        },
        {
          "title": "Street Fighter",
          "winner": "15000/-",
          "firstRunner": "10000/-",
          "total": "25000/-",
          "registrationReg": "2000/-",
          "earlyBird": "1800/-"
        }
      ]
    }
  ],
  LiteraryCompetitions: [
    {
      "title": "Literary Competitions",
      "Events": [
        "Baitbazi",
        "Kasauuti",
        "Essay Writing",
        "Journal Writing",
        "Debates (English and Urdu)",
        "Parliamentary Debates",
        "Presidential Debates",
        "Talk Show Debates",
        "Engineering Debates"
      ],
      "EventDetail": [
        {
          "title": "Baitbazi",
          "winner": "7000/-",
          "firstRunner": "3500/-",
          "total": "10500/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Kasauuti",
          "winner": "7000/-",
          "firstRunner": "3500/-",
          "total": "10500/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Essay Writing",
          "winner": "7000/-",
          "firstRunner": "3500/-",
          "total": "10500/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Journal Writing",
          "winner": "7000/-",
          "firstRunner": "3500/-",
          "total": "10500/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Debates (English and Urdu)",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Parliamentary Debates",
          "winner": "15000/-",
          "firstRunner": "7500/-",
          "total": "22500/-",
          "registrationReg": "1500/-",
          "earlyBird": "1200/-"
        },
        {
          "title": "Presidential Debates",
          "winner": "15000/-",
          "firstRunner": "7500/-",
          "total": "22500/-",
          "registrationReg": "1500/-",
          "earlyBird": "1200/-"
        },
        {
          "title": "Talk Show Debates",
          "winner": "15000/-",
          "firstRunner": "7500/-",
          "total": "22500/-",
          "registrationReg": "1500/-",
          "earlyBird": "1200/-"
        },
        {
          "title": "Engineering Debates",
          "winner": "15000/-",
          "firstRunner": "7500/-",
          "total": "22500/-",
          "registrationReg": "1500/-",
          "earlyBird": "1200/-"
        }
      ]
    }
  ],


  MedigraphyCompetitions: [
    {
      "title": "Medigraphy Competitions",
      "Events": [
        "Mobile Photography",
        "Reel Competition",
        "Architecture Photography",
        "Poster Designing",
        "Nexus Vlog",
        "Short Film / Promo"
      ],
      "EventDetail": [
        {
          "title": "Mobile Photography",
          "winner": "7000/-",
          "firstRunner": "3500/-",
          "total": "10500/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Reel Competition",
          "winner": "15000/-",
          "firstRunner": "7500/-",
          "total": "22500/-",
          "registrationReg": "1500/-",
          "earlyBird": "1200/-"
        },
        {
          "title": "Architecture Photography",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "1500/-",
          "earlyBird": "1200/-"
        },
        {
          "title": "Poster Designing",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Nexus Vlog",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "2000/-",
          "earlyBird": "1800/-"
        },
        {
          "title": "Short Film / Promo",
          "winner": "7000/-",
          "firstRunner": "3500/-",
          "total": "10500/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        }
      ]
    }
  ],

  PerformingArtsCompetitions: [
    {
      "title": "Performing Arts Competitions",
      "Events": [
        "Dance Solo (Boys Only)",
        "Cultural Dance (Boys & Girls)",
        "Drama",
        "Mimes",
        "Painting",
        "Sketching",
        "Food Crafting",
        "Calligraphy",
        "Standup Comedy",
        "Art Exhibition",
        "T-Shirt Painting",
        "Script Writing",
        "Monologue"
      ],
      "EventDetail": [
        {
          "title": "Dance Solo (Boys Only)",
          "winner": "7000/-",
          "firstRunner": "3500/-",
          "total": "10500/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Cultural Dance (Boys & Girls)",
          "winner": "15000/-",
          "firstRunner": "10000/-",
          "total": "25000/-",
          "registrationReg": "2500/-",
          "earlyBird": "2200/-"
        },
        {
          "title": "Drama",
          "winner": "30000/-",
          "firstRunner": "18000/-",
          "total": "48000/-",
          "registrationReg": "1000 per person/-",
          "earlyBird": "800 per person/-"
        },
        {
          "title": "Mimes",
          "winner": "15000/-",
          "firstRunner": "10000/-",
          "total": "25000/-",
          "registrationReg": "3000/-",
          "earlyBird": "2500/-"
        },
        {
          "title": "Painting",
          "winner": "7000/-",
          "firstRunner": "3500/-",
          "total": "10500/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Sketching",
          "winner": "7000/-",
          "firstRunner": "3500/-",
          "total": "10500/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Food Crafting",
          "winner": "7000/-",
          "firstRunner": "3500/-",
          "total": "10500/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Calligraphy",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Standup Comedy",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Art Exhibition",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "T-Shirt Painting",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "1500/-",
          "earlyBird": "1200/-"
        },
        {
          "title": "Script Writing",
          "winner": "7000/-",
          "firstRunner": "3500/-",
          "total": "10500/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Monologue",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "1500/-",
          "earlyBird": "1200/-"
        }
      ]
    }
  ],

  MentalWellbeingActivities: [
    {
      "title": "Mental Wellbeing Activities",
      "Events": ["Yoga", "Drum Circle", "Pet Show"],
      "EventDetail": [
        {
          "title": "Yoga",
          "winner": "7000/-",
          "firstRunner": "3500/-",
          "total": "10500/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Drum Circle",
          "winner": "7000/-",
          "firstRunner": "3500/-",
          "total": "10500/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Pet Show",
          "winner": "7000/-",
          "firstRunner": "3500/-",
          "total": "10500/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        }
      ]
    }
  ],

  MusicalCompetitions: [
    {
      "title": "Musical Competitions",
      "Events": [
        "Battle of Bands",
        "Rap Battle",
        "Singing Competition (Solo)"
      ],
      "EventDetail": [
        {
          "title": "Battle of Bands",
          "winner": "20000/-",
          "firstRunner": "10000/-",
          "total": "30000/-",
          "registrationReg": "3500/-",
          "earlyBird": "3000/-"
        },
        {
          "title": "Rap Battle",
          "winner": "10000/-",
          "firstRunner": "7500/-",
          "total": "17500/-",
          "registrationReg": "1500/-",
          "earlyBird": "1200/-"
        },
        {
          "title": "Singing Competition (Solo)",
          "winner": "15000/-",
          "firstRunner": "7500/-",
          "total": "22500/-",
          "registrationReg": "1500/-",
          "earlyBird": "1200/-"
        }
      ]
    }
  ],

  ITandComputingCompetitions: [
    {
      "title": "IT and Computing Competitions",
      "Events": [
        "Code Champs",
        "UX/UI Design Competition",
        "Webathon",
        "Capture The Flag (CTF)"
      ],
      "EventDetail": [
        {
          "title": "Code Champs",
          "winner": "15000/-",
          "firstRunner": "7500/-",
          "total": "22500/-",
          "registrationReg": "1500/-",
          "earlyBird": "1200/-"
        },
        {
          "title": "UX/UI Design Competition",
          "winner": "20000/-",
          "firstRunner": "10000/-",
          "total": "30000/-",
          "registrationReg": "3000/-",
          "earlyBird": "2500/-"
        },
        {
          "title": "Webathon",
          "winner": "20000/-",
          "firstRunner": "10000/-",
          "total": "30000/-",
          "registrationReg": "3000/-",
          "earlyBird": "2500/-"
        },
        {
          "title": "Capture The Flag (CTF)",
          "winner": "5000/-",
          "firstRunner": "3000/-",
          "total": "8000/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        }
      ]
    }
  ],


  OutdoorSportsCompetitions: [
    {
      "title": "Outdoor Sports Competitions",
      "Events": [
        "Volleyball (Boys & Girls)",
        "Basketball (Boys & Girls)",
        "Cricket (Boys & Girls)",
        "Tug Of War (Boys & Girls)",
        "Wall Climbing (Boys & Girls)",
        "Archery (Boys & Girls)",
        "Badminton (Boys & Girls)",
        "Futsal (Boys & Girls)"
      ],
      "EventDetail": [
        {
          "title": "Volleyball (Boys & Girls)",
          "winner": "15000/-",
          "firstRunner": "7500/-",
          "total": "22500/-",
          "registrationReg": "3500/-",
          "earlyBird": "3000/-"
        },
        {
          "title": "Basketball (Boys & Girls)",
          "winner": "15000/-",
          "firstRunner": "7500/-",
          "total": "22500/-",
          "registrationReg": "3500/-",
          "earlyBird": "3000/-"
        },
        {
          "title": "Cricket (Boys & Girls)",
          "winner": "50000/-",
          "firstRunner": "25000/-",
          "total": "75000/-",
          "registrationReg": "3500/-",
          "earlyBird": "3000/-"
        },
        {
          "title": "Tug Of War (Boys & Girls)",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "2500/-",
          "earlyBird": "2200/-"
        },
        {
          "title": "Wall Climbing (Boys & Girls)",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "1500/-",
          "earlyBird": "1200/-"
        },
        {
          "title": "Archery (Boys & Girls)",
          "winner": "7000/-",
          "firstRunner": "3500/-",
          "total": "10500/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Badminton (Boys & Girls)",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Futsal (Boys & Girls)",
          "winner": "20000/-",
          "firstRunner": "10000/-",
          "total": "30000/-",
          "registrationReg": "4000/-",
          "earlyBird": "3500/-"
        }
      ]
    }
  ],

  IndoorSportsCompetitions: [
    {
      "title": "Indoor Sports Competitions",
      "Events": [
        "Table Football (Boys & Girls)",
        "Arm Wrestling (Boys & Girls)",
        "Table Tennis (Boys & Girls)",
        "Snooker (Boys & Girls)",
        "Squash (Boys & Girls)",
        "Ludo",
        "Chess"
      ],
      "EventDetail": [
        {
          "title": "Table Football (Boys & Girls)",
          "winner": "7000/-",
          "firstRunner": "3500/-",
          "total": "10500/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Arm Wrestling (Boys & Girls)",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Table Tennis (Boys & Girls)",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Snooker (Boys & Girls)",
          "winner": "20000/-",
          "firstRunner": "10000/-",
          "total": "30000/-",
          "registrationReg": "1500/-",
          "earlyBird": "1200/-"
        },
        {
          "title": "Squash (Boys & Girls)",
          "winner": "15000/-",
          "firstRunner": "7500/-",
          "total": "22500/-",
          "registrationReg": "3000/-",
          "earlyBird": "2500/-"
        },
        {
          "title": "Ludo",
          "winner": "7000/-",
          "firstRunner": "3000/-",
          "total": "10000/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Chess",
          "winner": "7000/-",
          "firstRunner": "3000/-",
          "total": "10000/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        }
      ]
    }
  ],

  RoboticCompetitions: [
    {
      "title": "Robotic Competitions",
      "Events": [
        "Speed Wiring",
        "Line Following Robot (LFR)",
        "Off Road Race",
        "Sumo War",
        "Obstacle Avoidance",
        "Truss Bridge"
      ],
      "EventDetail": [
        {
          "title": "Speed Wiring",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "1500/-",
          "earlyBird": "1200/-"
        },
        {
          "title": "Line Following Robot (LFR)",
          "winner": "15000/-",
          "firstRunner": "7500/-",
          "total": "22500/-",
          "registrationReg": "2000/-",
          "earlyBird": "1800/-"
        },
        {
          "title": "Off Road Race",
          "winner": "15000/-",
          "firstRunner": "7500/-",
          "total": "22500/-",
          "registrationReg": "1500/-",
          "earlyBird": "1200/-"
        },
        {
          "title": "Sumo War",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "2500/-",
          "earlyBird": "2200/-"
        },
        {
          "title": "Obstacle Avoidance",
          "winner": "15000/-",
          "firstRunner": "7500/-",
          "total": "22500/-",
          "registrationReg": "1500/-",
          "earlyBird": "1200/-"
        },
        {
          "title": "Truss Bridge",
          "winner": "15000/-",
          "firstRunner": "7500/-",
          "total": "22500/-",
          "registrationReg": "1500/-",
          "earlyBird": "1200/-"
        }
      ]
    }
  ],

  SocialEvents: [
    {
      "title": "Social Events",
      "Events": [
        "Concert",
        "Qawali Night",
        "Auto Show",
        "Theme Dinner"
      ],
      "EventDetail": [
        {
          "title": "Concert",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Qawali Night",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Auto Show",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Theme Dinner",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        }
      ]
    }
  ],

  AirNexusJuniorCompetitions: [
    {
      "title": "Air Nexus Junior Competitions",
      "Events": [
        "Egg Drop Challenge",
        "Recycling Challenge",
        "Scavenger Hunt",
        "Red Light Green Light"
      ],
      "EventDetail": [
        {
          "title": "Egg Drop Challenge",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Recycling Challenge",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "2000/-",
          "earlyBird": "1800/-"
        },
        {
          "title": "Scavenger Hunt",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "2000/-",
          "earlyBird": "1800/-"
        },
        {
          "title": "Red Light Green Light",
          "winner": "7000/-",
          "firstRunner": "3000/-",
          "total": "10000/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        }
      ]
    }
  ],

  PersonalWellbeingCompetitions: [
    {
      "title": "Personal Wellbeing Competitions",
      "Events": [
        "Make-Up (Girls Only)",
        "Hairstyling (Girls & Boys)",
        "Nail Painting (Girls Only)"
      ],
      "EventDetail": [
        {
          "title": "Make-Up (Girls Only)",
          "winner": "5000/-",
          "firstRunner": "3000/-",
          "total": "8000/-",
          "registrationReg": "1500/-",
          "earlyBird": "1200/-"
        },
        {
          "title": "Hairstyling (Girls & Boys)",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        },
        {
          "title": "Nail Painting (Girls Only)",
          "winner": "7000/-",
          "firstRunner": "5000/-",
          "total": "12000/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        }
      ]
    }
  ],

  QuizCompetitions: [
    {
      "title": "Quiz Competitions",
      "Events": [
        "Physics Quiz Competition",
        "Maths Quiz Competition"
      ],
      "EventDetail": [
        {
          "title": "Physics Quiz Competition",
          "winner": "10000/-",
          "firstRunner": "7500/-",
          "total": "17500/-",
          "registrationReg": "1500/-",
          "earlyBird": "1200/-"
        },
        {
          "title": "Maths Quiz Competition",
          "winner": "10000/-",
          "firstRunner": "5000/-",
          "total": "15000/-",
          "registrationReg": "1000/-",
          "earlyBird": "800/-"
        }
      ]
    }
  ]


};

const { IslamicCompetitions, EntrepreneurialActivities, QuizCompetitions, PersonalWellbeingCompetitions, AirNexusJuniorCompetitions, SocialEvents, RoboticCompetitions, IndoorSportsCompetitions, OutdoorSportsCompetitions, ITandComputingCompetitions, MusicalCompetitions, MentalWellbeingActivities, PerformingArtsCompetitions, MedigraphyCompetitions, LiteraryCompetitions, EGamesCompetitions } = competitions
export {
IslamicCompetitions, EntrepreneurialActivities, QuizCompetitions, PersonalWellbeingCompetitions, AirNexusJuniorCompetitions, SocialEvents, RoboticCompetitions, IndoorSportsCompetitions, OutdoorSportsCompetitions, ITandComputingCompetitions, MusicalCompetitions, MentalWellbeingActivities, PerformingArtsCompetitions, MedigraphyCompetitions, LiteraryCompetitions, EGamesCompetitions
}
